<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="card border-0 shadow-none">
          <div class="card-body">
            <div v-if="!currentBooking.selectedRoom">
              <div class="row">
                <div class="col text-center">
                  <div
                    class="card border-0 cursor-pointer"
                    :class="
                      form.retreat == 'uk'
                        ? 'bg-success shadow text-white'
                        : 'bg-light shadow-none'
                    "
                    @click="form.retreat = 'uk'"
                  >
                    <div class="card-body">
                      <h4 class="mb-0">UK</h4>
                    </div>
                  </div>
                </div>

                <div class="col text-center">
                  <div
                    class="card border-0 cursor-pointer"
                    :class="
                      form.retreat == 'malta'
                        ? 'bg-success shadow text-white'
                        : 'bg-light shadow-none'
                    "
                    @click="form.retreat = 'malta'"
                  >
                    <div class="card-body">
                      <h4 class="mb-0">Malta</h4>
                    </div>
                  </div>
                </div>
              </div>

              <!--  -->

              <template v-if="form.retreat">
                <div class="row mt-5 mb-4 text-center">
                  <div class="col">
                    <h5 class="mb-0 fw-bold">
                      Arrival: {{ form.dates.startDate | formatDate }} |
                      Departure: {{ form.dates.endDate | formatDate }} |
                      {{ nights + " Nights Stay" }}
                    </h5>
                  </div>
                </div>
                <div class="row text-center my-3">
                  <div class="col-auto mx-auto">
                    <date-range-picker
                      ref="picker"
                      :opens="datePickerOptions.opens"
                      :locale-data="{
                        firstDay: 1,
                        format: 'DD-MM-YYYY HH:mm:ss',
                      }"
                      :minDate="datePickerOptions.minDate"
                      :maxDate="datePickerOptions.maxDate"
                      :singleDatePicker="datePickerOptions.singleDatePicker"
                      :timePicker="datePickerOptions.timePicker"
                      :timePicker24Hour="datePickerOptions.timePicker24Hour"
                      :showWeekNumbers="datePickerOptions.showWeekNumbers"
                      :showDropdowns="datePickerOptions.showDropdowns"
                      :autoApply="datePickerOptions.autoApply"
                      :ranges="datePickerOptions.ranges"
                      v-model="form.dates"
                      @update="updateValues"
                      @toggle="checkOpen"
                      :linkedCalendars="datePickerOptions.linkedCalendars"
                      :dateFormat="datePickerOptions.dateFormat"
                    >
                      <template v-slot:input="picker" style="min-width: 350px">
                        {{ picker.startDate | formatDate }} -
                        {{ picker.endDate | formatDate }}
                      </template>
                    </date-range-picker>
                  </div>
                </div>

                <div class="row">
                  <div class="col-auto mx-auto"></div>
                </div>
              </template>

              <!--  -->

              <template v-if="searching">
                <div class="row my-5">
                  <div class="col text-center">
                    <i class="fad fa-spinner fa-spin fa-5x text-success"></i>
                  </div>
                </div>
              </template>

              <!--  -->

              <template v-if="availableRooms.length > 0">
                <div class="row">
                  <div
                    class="col-12 col-md-4 mb-3"
                    v-for="r in availableRooms"
                    :key="r.id"
                  >
                    <div class="card border-0 bg-light">
                      <div class="card-body text-center">
                        <img
                          :src="r.image"
                          :alt="r.name"
                          class="card-img-top mb-2"
                        />
                        <h5 class="mb-0">
                          {{ r.name }}
                        </h5>
                        <span
                          ><small>{{ r.type }}</small></span
                        >
                        <div>
                          <small class="text-capitalize"
                            >{{ r.floor }} floor</small
                          >
                        </div>
                        <hr class="my-2" />
                        <div class="row">
                          <div class="col">
                            <div class="mb-0">Individual</div>
                            <div class="mb-0 fw-bold">
                              {{ r.pricing.standard_price }}
                            </div>
                          </div>
                          <div class="col">
                            <div class="mb-0">Shared</div>
                            <div class="mb-0 fw-bold">
                              {{ r.pricing.standard_shared_price }}
                            </div>
                          </div>
                        </div>
                        <!--  -->

                        <button
                          class="btn btn-success btn-sm mt-3"
                          @click="selectRoom(r)"
                        >
                          <i class="far fa-check me-2"></i>Select Room
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <!--  -->
            </div>

            <div v-else>
              <div class="booking-summary">
                <div class="row">
                  <div class="col">
                    <h4 class="fw-bold text-success mb-3">
                      Ongoing Booking Summary
                    </h4>
                  </div>
                  <div class="col-auto ms-auto">
                    <button
                      class="btn btn-sm btn-outline-success"
                      @click="closeSummary"
                    >
                      <i class="far fa-times"></i>
                    </button>
                  </div>
                </div>
                <div class="row text-center mb-3">
                  <div class="col-md-4">
                    <div class="card border-0 bg-light shadow-none">
                      <div class="card-body">
                        <h6 class="text-success">Arrival</h6>
                        <h5 class="fw-bold text-success">
                          {{ currentBooking.dates.startDate | formatDate }}
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="card border-0 bg-light shadow-none">
                      <div class="card-body">
                        <h6 class="text-success">Departure</h6>
                        <h5 class="fw-bold text-success">
                          {{ currentBooking.dates.endDate | formatDate }}
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="card border-0 bg-light shadow-none">
                      <div class="card-body">
                        <h6 class="text-success">Room</h6>
                        <h5 class="fw-bold text-success">
                          {{ currentBooking.selectedRoom.name }}
                          <small
                            >/ {{ currentBooking.selectedRoom.floor }}</small
                          >
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row text-center mb-3">
                  <div class="col-md-4">
                    <div class="card border-0 bg-light shadow-none">
                      <div class="card-body">
                        <h6 class="text-success">Individual Price</h6>
                        <h5 class="fw-bold text-success">
                          £{{
                            currentBooking.selectedRoom.pricing.standard_price.toFixed(
                              2
                            )
                          }}
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="card border-0 bg-light shadow-none">
                      <div class="card-body">
                        <h6 class="text-success">Shared Price</h6>
                        <h5 class="fw-bold text-success">
                          £{{
                            currentBooking.selectedRoom.pricing.standard_shared_price.toFixed(
                              2
                            )
                          }}
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="card border-0 bg-light shadow-none">
                      <div class="card-body">
                        <h6 class="text-success">Total Price</h6>
                        <h5 class="fw-bold text-success">
                          £{{ totalBookingPrice.toFixed(2) }}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="alert alert-success"
                v-if="currentBooking.clients.length == 0"
              >
                <p>
                  Add clients to the booking by searching via email, name or ID.
                  If more than one client is added, the individual price is no
                  longer available
                </p>
              </div>
              <form
                class="my-3"
                @submit.prevent
                v-if="currentBooking.clients.length < 2"
              >
                <input
                  class="form-control me-2"
                  type="search"
                  v-model="clientSearch"
                  @input="searchForClient"
                  placeholder="Search for client"
                  aria-label="Search"
                />
                <div
                  class="bg-white mt-2"
                  style="z-index: 2000; height: 600px; overflow: scroll"
                  v-if="clients.length > 0"
                >
                  <ul class="list-group-flush">
                    <li
                      class="list-group-item cursor-pointer"
                      v-for="c in clients"
                      :key="c.id"
                      @click="selectClient(c)"
                    >
                      {{ c.name }} / {{ c.email }}
                    </li>
                  </ul>
                </div>
              </form>

              <!-- Clients on booking -->
              <h5
                class="fw-bold text-success mb-3"
                v-if="currentBooking.clients.length > 0"
              >
                Client(s) added to booking
              </h5>
              <div class="row mb-4">
                <div
                  class="col-md-6"
                  v-for="(c, ckey) in currentBooking.clients"
                  :key="c.id"
                >
                  <div class="card border-0 bg-light">
                    <div class="card-body">
                      <h6 class="fw-bold text-success d-inline mb-2">
                        {{ c.name }}
                      </h6>
                      <span v-if="ckey === 0"
                        ><small>(Lead client)</small></span
                      >
                      <p>
                        {{ c.email }} /
                        {{
                          c.phone
                            ? c.phone
                            : c.mobile
                            ? c.mobile
                            : "no number set"
                        }}
                      </p>
                      <div>
                        <button
                          class="btn btn-sm btn-outline-danger float-end"
                          @click="removeClient(ckey)"
                        >
                          <i class="far fa-trash"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-3" v-if="currentBooking.clients.length > 1">
                <div class="col">
                  <div class="card border-0 bg-light shadow-sm">
                    <div class="card-body">
                      <h5 class="fw-bold text-success">Twin Beds?</h5>
                      <div class="row">
                        <div class="col-auto">
                          <div class="form-check form-switch">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="clientsTwinBeds"
                              v-model="currentBooking.twin_beds"
                            />
                            <label
                              class="form-check-label"
                              for="clientsTwinBeds"
                              >Are the clients in separate beds?</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="alert alert-info"
                v-if="currentBooking.clients.length > 0"
              >
                <h6 class="fw-bold mb-0">
                  {{ currentBooking.clients.length }} client(s) arriving in
                  {{ form.retreat == "uk" ? "the UK" : "Malta" }} on
                  {{ currentBooking.dates.startDate | formatDate }} and leaving
                  on {{ currentBooking.dates.endDate | formatDate }}, staying in
                  {{ currentBooking.selectedRoom.name }} ({{
                    currentBooking.selectedRoom.floor + " floor"
                  }}) for a total price of £{{ totalBookingPrice.toFixed(2) }}
                </h6>
              </div>

              <!-- Discount -->

              <div class="row mb-4">
                <div class="col my-auto">
                  <div class="card border-0 bg-light shadow-sm">
                    <div class="card-body">
                      <div class="row g-3 align-items-center">
                        <div class="col-auto">
                          <label for="inputDiscount" class="col-form-label"
                            >Apply discount</label
                          >
                        </div>
                        <div class="col-auto col-md-4">
                          <input
                            type="number"
                            id="inputDiscount"
                            v-model="currentBooking.discount"
                            class="form-control"
                            aria-describedby="discountHelperText"
                            @input="checkDiscount"
                            :disabled="currentBooking.clients.length == 0"
                          />
                          <textarea
                            v-if="currentBooking.discount > 0"
                            class="form-control mt-3"
                            rows="2"
                            v-model="currentBooking.discount_reason"
                            placeholder="Add a discount reason..."
                          ></textarea>
                          <small v-if="currentBooking.discount > 0"
                            >A discount reason must be provided</small
                          >
                        </div>
                        <div class="col-auto">
                          <span id="discountHelperText" class="form-text">
                            Must be be in pounds - ie. 100 (one hundred) - max
                            £400
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--  -->

              <div class="row">
                <div class="col-auto ms-auto my-auto">
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="sendEmails"
                      v-model="currentBooking.send_emails"
                    />
                    <label class="form-check-label" for="sendEmails"
                      >Send emails to clients?</label
                    >
                  </div>
                </div>

                <div class="col-auto my-auto" v-if="currentBooking.send_emails">
                  <div class="form-check form-switch">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="sendEmailsToBoth"
                      v-model="currentBooking.send_emails_to_all"
                    />
                    <label class="form-check-label" for="sendEmailsToBoth"
                      >Send payment emails to all clients?</label
                    >
                  </div>
                </div>
                <div class="col-auto my-auto">
                  <button
                    class="btn btn-success"
                    @click="completeBooking"
                    :disabled="!canSubmit"
                  >
                    <i class="far fa-check me-2"></i>Complete Booking
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  data() {
    return {
      form: {
        retreat: "",
        dates: {
          startDate: null,
          endDate: null,
        },
      },
      datePickerOptions: {
        opens: "inline",
        minDate: moment().format("YYYY-MM-DD 00:00:00"),
        maxDate: null,
        singleDatePicker: false,
        timePicker: false,
        timePicker24Hour: false,
        showWeekNumbers: false,
        showDropdowns: true,
        autoApply: true,
        linkedCalendars: true,
        ranges: false,
        // dateFormat: (classes, date) => {
        //   // console.log(classes);
        //   // console.log(date);
        // },
      },
      nights: 3,
      searching: false,
      availableRooms: [],
      currentBooking: {
        selectedRoom: null,
        dates: {
          startDate: null,
          endDate: null,
        },
        clients: [],
        discount: 0,
        discount_reason: "",
        send_emails_to_all: false,
        send_emails: false,
        twin_beds: false,
      },
      clientSearch: "",
      clients: [],
    };
  },
  computed: {
    canSubmit() {
      if (this.currentBooking.clients.length == 0) {
        return false;
      }

      if (this.currentBooking.discount == 0) {
        return true;
      }

      if (this.currentBooking.discount > 0) {
        if (this.currentBooking.discount_reason.length > 3) {
          return true;
        }
        return false;
      }

      return false;
    },
    totalBookingPrice() {
      var price = 0;
      if (this.currentBooking.clients.length == 0) {
      }

      if (this.currentBooking.clients.length == 1) {
        price = this.currentBooking.selectedRoom.pricing.standard_price;
      }

      if (this.currentBooking.clients.length == 2) {
        price =
          this.currentBooking.selectedRoom.pricing.standard_shared_price * 2;
      }

      return Math.abs(price - +this.currentBooking.discount);
    },
  },
  watch: {
    form: {
      handler: function (val, oldVal) {
        this.currentBooking.dates = val.dates;
        if (val.retreat != oldVal.retreat) {
          this.findRooms();
        }
      },
      deep: true,
    },
  },
  methods: {
    checkDiscount() {
      if (
        this.currentBooking.discount > 400 ||
        this.currentBooking.discount > this.totalBookingPrice
      ) {
        this.currentBooking.discount = 0;
      }
    },
    completeBooking() {
      // Send off the booking
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/bookings", this.currentBooking)
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.$router.push({
            path: "/clients/" + data.client,
            hash: "retreat-bookings",
            query: {
              "retreat-booking": data.booking,
            },
          });
        });
    },
    closeSummary() {
      this.currentBooking = {
        selectedRoom: null,
        dates: {
          startDate: this.form.dates.startDate,
          endDate: this.form.dates.endDate,
        },
        clients: [],
        discount: 0,
        discount_reason: "",
        send_emails: false,
        send_emails_to_all: false,
        twin_beds: false,
      };
    },
    removeClient(key) {
      this.currentBooking.clients.splice(key, 1);
    },
    selectClient(client) {
      this.currentBooking.clients.push(client);
      this.clients = [];
      this.clientSearch = "";
    },
    selectRoom(room) {
      this.currentBooking.selectedRoom = room;

      if (this.$route.query.client) {
        this.fetchClientFromUrl();
      }
    },
    fetchClientFromUrl() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL + "/clients/" + this.$route.query.client
        )
        .then(({ data }) => {
          this.currentBooking.clients.push(data);
        });
    },
    checkOpen() {},
    updateValues(dates) {
      console.log(dates);

      var start = moment(dates.startDate).startOf("day");
      var end = moment(dates.endDate).startOf("day");

      this.nights = Math.abs(start.diff(end, "days", true));

      this.findRooms();
    },
    searchForClient() {
      if (this.clientSearch.length >= 3) {
        this.$axios
          .post(process.env.VUE_APP_API_URL + "/clients/search", {
            search: this.clientSearch,
          })
          .then(({ data }) => {
            let bookingClientData = Object.values(this.currentBooking.clients);

            if (bookingClientData && bookingClientData.length) {
              bookingClientData.forEach(bc => {
                data = data.filter(c => {
                  return c.id != bc.id;
                });
              });
            }

            this.clients = data;
          });
      }
    },
    findRooms() {
      this.searching = true;
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/bookings/find-rooms", this.form)
        .then(({ data }) => {
          this.availableRooms = data;
          this.searching = false;
        });
    },
  },
  filters: {
    formatDate(date) {
      if (date) {
        return moment(date).format("LL");
      } else {
        return "Not Set";
      }
    },
  },
  mounted() {
    this.$store.sidebarOpen = false;
  },
  components: {
    DateRangePicker,
  },
};
</script>

<style></style>
